import type { CreateMtaQuoteDto } from '../models/CreateMtaQuoteDto';
import type { MotorPolicyDocumentDto } from '../models/MotorPolicyDocumentDto';
import type { MotorPolicyDto } from '../models/MotorPolicyDto';
import type { MotorPolicySummaryDto } from '../models/MotorPolicySummaryDto';
import type { MtaQuoteDto } from '../models/MtaQuoteDto';
import type { PatchPolicyRequestDto } from '../models/PatchPolicyRequestDto';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class MotorPolicyService {
  constructor(readonly httpRequest: BaseHttpRequest) {}

  getMotorPolicy(id: string, policyVersion?: number): CancelablePromise<MotorPolicyDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-policy/{id}',
      path: {
        id,
      },
      query: {
        policyVersion,
      },
    });
  }

  patchMotorPolicy(
    id: string,
    requestBody?: PatchPolicyRequestDto
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/motor-policy/{id}',
      path: {
        id,
      },
      body: requestBody,
    });
  }

  getMotorPolicy1(
    state?: string[],
    motorQuoteId?: string
  ): CancelablePromise<MotorPolicySummaryDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-policy',
      query: {
        state,
        motorQuoteId,
      },
    });
  }

  getMotorPolicyDocuments(id: string): CancelablePromise<MotorPolicyDocumentDto[]> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-policy/{id}/documents',
      path: {
        id,
      },
    });
  }

  postMotorPolicyMtaQuote(
    id: string,
    requestBody?: CreateMtaQuoteDto
  ): CancelablePromise<MtaQuoteDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motor-policy/{id}/mta-quote',
      path: {
        id,
      },
      body: requestBody,
    });
  }
}
