import { DriverDto, ExcessAmountDto, PaymentPlanDto } from '../../api/v1';
import { DriverFormData } from '../../types';
import { addCurrency } from './formatters';
import { isApiDrivers, isNo, isYes } from './typeguards';
import { EXCESSES, PaymentPlansEnum, PaymentTypesEnum } from '../../constants/payments';
import { AddOnsWithMeta } from '../../types/addOns';

export function getProposer(drivers?: Array<DriverFormData>): DriverFormData | undefined;
export function getProposer(drivers?: Array<DriverDto>): DriverDto | undefined;
export function getProposer(drivers?: null): undefined;
export function getProposer(
  drivers?: Array<DriverFormData> | Array<DriverDto> | null
): DriverFormData | DriverDto | undefined {
  if (isApiDrivers(drivers)) {
    return drivers.find(driver => driver.isProposer);
  }
  return drivers?.find(driver => isYes(driver.isProposer?.id));
}

export function getAdditionalDrivers(
  drivers?: Array<DriverFormData> | null
): Array<DriverFormData>;
export function getAdditionalDrivers(drivers?: Array<DriverDto>): Array<DriverDto>;
export function getAdditionalDrivers(drivers?: null): undefined;
export function getAdditionalDrivers(
  drivers?: Array<DriverFormData> | Array<DriverDto> | null
): Array<DriverFormData> | Array<DriverDto> | undefined {
  if (isApiDrivers(drivers)) {
    return drivers.filter(driver => !driver.isProposer);
  }
  return drivers?.filter(driver => isNo(driver.isProposer?.id));
}

export const getSelectedPaymentPlan = (paymentPlans?: Array<PaymentPlanDto> | null) =>
  paymentPlans?.find(paymentPlan => paymentPlan.default);

export const getAnnualPaymentPlan = (paymentPlans?: Array<PaymentPlanDto> | null) =>
  paymentPlans?.find(plan => plan.paymentFrequency?.code === 'ANN');

export const getMonthlyPaymentPlan = (paymentPlans?: Array<PaymentPlanDto> | null) =>
  paymentPlans?.find(plan => plan.paymentFrequency?.code === 'MON');

export const isAnnualPaymentPlan = (paymentPlanName?: string | null) =>
  paymentPlanName === PaymentPlansEnum.ANN || paymentPlanName === PaymentTypesEnum.ANNUAL;

export const isMonthlyPaymentPlan = (paymentPlanName?: string | null) =>
  paymentPlanName === PaymentTypesEnum.MONTHLY ||
  paymentPlanName?.includes(PaymentPlansEnum.MON);

export const getPaymentPlanNameFromCode = (
  paymentPlanName?: string | null,
  isMta?: boolean,
  isRefund?: boolean
): string => {
  if (isAnnualPaymentPlan(paymentPlanName)) {
    if (isMta) {
      return isRefund ? 'One-off refund' : 'One-off payment';
    }
    return 'Annually';
  }
  if (isMonthlyPaymentPlan(paymentPlanName)) {
    return isMta ? 'New monthly payments' : 'Monthly';
  }
  return '';
};

export const getPolicyExcess = (excesses?: Array<ExcessAmountDto> | null) =>
  excesses?.find(excess => excess.type?.code === EXCESSES.policy);

export const getExcessTotalAmount = (excess?: ExcessAmountDto) => excess?.total;

export const getPolicyExcessTotalAmount = (excesses?: Array<ExcessAmountDto> | null) => {
  const policyExcess = getExcessTotalAmount(getPolicyExcess(excesses));
  if (!policyExcess) {
    return undefined;
  }

  return addCurrency(policyExcess);
};

export const getSelectedAddOnsNames = (addOns?: Array<AddOnsWithMeta> | null) =>
  addOns?.reduce<string[]>((result, current) => {
    if (current.selected && current.description) {
      const { description } = current;
      return [...result, description];
    }

    return result;
  }, []);
